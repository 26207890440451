<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('MENU.partners')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr v-if="data.full_name">
                                            <th>{{$t('partners.full_name')}}</th>
                                            <td>{{data.full_name}}</td>
                                        </tr>
                                        <tr v-if="data.email">
                                            <th>{{$t('partners.email')}}</th>
                                            <td>{{data.email}}</td>
                                        </tr>
                                        <tr v-if="data.mobile">
                                            <th>{{$t('partners.mobile')}}</th>
                                            <td>{{data.mobile}}</td>
                                        </tr>
                                        <tr v-if="data.phone">
                                            <th>{{$t('partners.phone')}}</th>
                                            <td>{{data.phone}}</td>
                                        </tr>
                                        <tr v-if="data.company_type">
                                            <th>{{$t('partners.company_type')}}</th>
                                            <td>{{data.company_type == 2 ? $t('partners.company') : $t('partners.individual')}}</td>
                                        </tr>
                                        <tr v-if="data.company_name">
                                            <th>{{$t('partners.company_name')}}</th>
                                            <td>{{data.company_name}}</td>
                                        </tr>
                                        <tr v-if="data.business_number">
                                            <th>{{$t('partners.business_number')}}</th>
                                            <td>{{data.business_number}}</td>
                                        </tr>
                                        <tr v-if="data.country_name">
                                            <th>{{$t('partners.country')}}</th>
                                            <td>{{data.country_name}}</td>
                                        </tr>
                                        <tr v-if="data.city_name">
                                            <th>{{$t('partners.city')}}</th>
                                            <td>{{data.city_name}}</td>
                                        </tr>
                                        <tr v-if="data.fields.length > 0">
                                            <th>{{$t('partners.fields')}}</th>
                                            <td>
                                              <span v-for="(row, index) in data.fields" class="badge bg-primary ml-2 mr-2 text-white" :key="index" style="font-size: 13px;">{{row.name}}</span>
                                            </td>
                                        </tr>


                                        <tr v-if="data.created_at">
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.created_at}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "view-partners",
        // components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: '/agents/partners',
                id: this.$route.params.id? this.$route.params.id : null,
                data: {},
                key: "partners",
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.partners"), route: {name:'partners.index'}}, {title: this.$t('view')}]);
            this.getData();

        },
    };
</script>